import { memo, useCallback, useEffect } from "react"
import { Button } from "@chakra-ui/react"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useSubscriptionContext } from "@app/providers/subscription"
import { useCartContext } from "@app/providers/cart"

const CheckoutBtn: React.FC = () => {
  const { cart, loading, gotoCart, count } = useCartContext()
  const { processRechargeCheckout } = useSubscriptionContext()
  const locales = useLocale(LOCALE_KEYS.CART)

  const handleCheckoutClick = useCallback(() => {
    const { shouldGoToNormalCheckout } = processRechargeCheckout({ lineItems: cart?.lines, isMainCart: true, isForced: true })

    if (shouldGoToNormalCheckout) {
      return gotoCart()
    }
  }, [cart, processRechargeCheckout, gotoCart])

  return (
    <Button isDisabled={!count} w="full" as="a" mt={5} onClick={handleCheckoutClick} isLoading={loading} cursor="pointer">
      {locales?.cartCheckout}
    </Button>
  )
}

const MemoCheckoutBtn = memo(CheckoutBtn)
export { MemoCheckoutBtn as CheckoutBtn }
